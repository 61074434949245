<template>
  <el-popover
    placement="bottom"
    width="300px"
    :popper-class="`add-team-popover ${props.name}`"
    trigger="click"
    :visible-arrow="false"
    :append-to-body="false"
    @hide="handleHide"
  >
    <el-input
      class="search-team-member"
      v-model="input"
      :placeholder="$t('team_manager.dialog.input_username_or_email')"
      @focus="hasSearched = false"
      @keyup.enter="getMemberByName"
    >
      <template #suffix>
        <el-icon class="el-input__icon" @click="getMemberByName">
          <Search />
        </el-icon>
      </template>
    </el-input>
    <template v-if="hasSearched">
      <div v-if="list.length > 0" class="members-list-wrap">
        <div v-for="(member, index) in list" :key="index" class="members-item">
          <div class="members-item-left">
            <div class="members-info-item username">{{ $tLabel($t('label.username')) }}{{ member.user_name || '-'}}</div>
            <div class="members-info-item email">{{ $tLabel($t('label.email')) }}{{ member.email || '-'}}</div>
          </div>
          <div class="members-item-right">
            <span class="invited" v-if="joinedMembers.includes(member.user_id)"
              >{{ $t('team_manager.dialog.joined') }}</span
            >
            <span class="invited" v-else-if="invitedMemvers.includes(member.user_id)"
              >{{ $t('team_manager.dialog.invited') }}</span
            >
            <span class="uninvit" v-else @click="handleInvit(member.user_id)">
              <svg-icon name="tianjiarenyaun"></svg-icon>
            </span
            >
          </div>
        </div>
      </div>
      <div class="no-members" v-else>{{ $t('team_manager.dialog.no_search_user') }}</div>
    </template>
    <template #reference><slot></slot></template>
  </el-popover>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue'
// import { useStore } from 'vuex'
import { message } from '@/hooks/useUI'
import { Search } from '@element-plus/icons-vue'
import { getInviteUser, postTeamMembers } from '@/services/team'
import { t } from 'app/i18n'

// const store = useStore()
const props = defineProps({
  name: {
    type: String,
    default: 'popover-class'
  },
  teamId: {
    type: Number,
    required: true
  },
  invitedMemvers: {
    type: Array
  },
  joinedMembers: {
    type: Array
  }
})

const emit = defineEmits(['updateMembers'])

const input = ref('')
const list = ref([])
const hasSearched = ref(false)

const getMemberByName = () => {
  if (!input.value.trim()) return
  getInviteUser({ user_name: input.value }).then((res) => {
    hasSearched.value = true
    if ('code' in res) {
      list.value = []
    } else {
      list.value = res
    }
  })
}
const handleInvit = (userId) => {
  postTeamMembers({
    user_id: userId,
    team_id: props.teamId
  }).then((res) => {
    if ('code' in res) {
      // if (res.code === 1) {
      //   store.commit('SHOWFREQUENCY', '单个团队添加成员超过 10 个限制')
      // } else {
      message(res.message, 'error')
      // }
    } else {
      message(t('team_manager.dialog.invited_success'))
      emit('updateMembers')
    }
  })
}
const handleHide = () => {
  input.value = ''
  hasSearched.value = false
}
</script>

<style lang='scss'>
.add-team-popover {
  // background: #252F58;
  background: rgba(255, 255, 255, 0) !important;
  // box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: var(--shadow) !important;
  // border-radius: 4px;
  padding: 0 !important;
  border: 0 !important;

  .search-team-member {
    .el-input__icon {
      cursor: pointer;
    }
    .el-input__inner {
      // background: rgba(51, 62, 107, 0.5);
      // border: 1px solid #333E6B;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      color: var(--color-text-1);
    }

    .el-input-group__append {
      background: rgba(51, 62, 107, 0.5);
      border-color: #333E6B;
      width: 30px;
      padding: 0;
      text-align: center;
      cursor: pointer;

      .searchbtn {
        padding: 8px 10px;
      }
    }
  }

  .members-list-wrap {
    margin-top: 8px;

    .members-item {
      display: flex;
      background: var(--color-bg-3);
      // border: 1px solid #323F71;
      border-radius: 4px;
      padding: 6px 16px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;

      .members-item-left {
        .username {
          font-size: 14px;
          line-height: 20px;
          color: var(--color-text-1);
          margin-bottom: 4px;
        }

        .email {
          font-size: 12px;
          color: #C4CADA;
        }
      }

      .members-item-right {
        .invited {
          color: var(--color-text-2);
          cursor: pointer;
        }

        .uninvit {
          color: #00cc92;
          cursor: pointer;

          img {
            vertical-align: middle;
            margin-right: 4px;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .no-members {
    margin-top: 5px;
    font-size: 14px;
    color: #C4CADA;
    background: var(--color-bg-3);
    padding: 20px;
    text-align: center;
  }
}
</style>
