<template>
  <div class="owner-sidebar__wrapper">
    <div class="sidebar-list">
      <div class="sidebar-item" :class="{ active: item.team_id === props.active }" v-for="item in props.list" :key="item.team_name" @click="changeTeam(item)">
        <el-tooltip
          :content="item.team_name"
          popper-class="plan-info-tips"
          :effect="$isDark() ? 'dark' : 'light'"
          :disabled="item.team_name.length < 9"
          :offset="15"
          placement="right"
        >
          <div style="width:120px;">
            {{ item.team_name }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="sidebar-action__wrapper">
      <el-button type="primary" @click="handleCreate">{{ $t('team_manager.create_team') }}</el-button>
    </div>
    <upgrade-dialog
      v-model:show="isShowUpgrade"
      :showPoint="false"
      :showNormal="false"
      :showTeam="true"
      type="A"
      upgradeType="enterprise"
    ></upgrade-dialog>
    <add-team-dialog :addVisible="visible" @closeAddTeam="closeAddTeam" @addTeam="submitTeam"  />
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue'
import addTeamDialog from './add-team-dialog.vue'
import { useState } from '@/hooks/useVuex'
import upgradeDialog from '@/components/common/upgradeDialog.vue'
// import { postTeamCooperation } from '@/services/team.js'
// import { message } from '@/hooks/useUI'

const props = defineProps({
  list: {
    required: true,
    default: () => {
      return []
    }
  },
  active: {
    required: true,
    default: 0
  }
})

const { userInfo } = useState('user', ['userInfo'])
const isShowUpgrade = ref(false)
const visible = ref(false)
const emit = defineEmits(['refresh', 'changeIndex'])

const handleCreate = () => {
  if (userInfo.value.group === 'free' || userInfo.value.group === 'professional') {
    isShowUpgrade.value = true
    return
  }
  visible.value = true
}

const changeTeam = (team) => {
  emit('changeIndex', team)
}

const closeAddTeam = () => {
  visible.value = false
}

const submitTeam = (formData) => {
  // emit('add', formData)
  visible.value = false
  emit('refresh')
}

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.owner-sidebar__wrapper {
  float: left;
  box-sizing: border-box;
  width: 145px;
  // height: calc(100% - 5px);
  border-right: 2px solid var(--color-border-2);
  // padding-bottom: 10px;
  .sidebar-list {
    text-align: right;
    height: calc(100vh - 255px);
    overflow-y: auto;
    width: 145px;
    &::-webkit-scrollbar {
      width: 0;
    }
    .sidebar-item {
      line-height: 40px;
      padding-right: 15px;
      padding-left: 8px;
      box-sizing: border-box;
      &>div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      cursor: pointer;
      color: var(--color-text-2);
      &.active {
        color: var(--color-text-1);
        background-color: var(--color-bg-3);
        border-right: 2px solid #00ab7a;
        border-radius: 2px 0px 0px 2px;
        &:hover {
          width: 100%;
        }
      }
      &:hover {
        // color: var(--color-text-1);
        background-color: var(--color-bg-3);
        border-radius: 2px 0px 0px 2px;
        width: calc(100% - 2px);
        padding-right: 13px;
      }
    }
  }
  .sidebar-action__wrapper {
    &.isNoData {
       border-top-width: 0;
       margin-top: 0;
    }
    box-shadow: 0 -10px 8px -10px var(--popover-box-shadow1), 0 0 0 0 #3f4251;
    // margin-top: 10px;
    border-top: 1px solid var(--color-border-2);
    padding: 15px 0;
    width: 125px;
    margin-left: 10px;
    text-align: center;
  }
}
</style>
