<template>
  <el-dialog
    v-model="visible"
    :title="props.type === 'update' ? $t('team_manager.edit_team') : $t('team_manager.create_team')"
    :before-close="closeAddTeam"
    custom-class="add-team__dialog"
    width="560px"
  >
    <div class="no-count__wrap" v-if="!hasPremission">
      <svg-icon name="info"></svg-icon>
      <span class="info">{{ $t('team_manager.dialog.create_team_info') }}</span>
      <span class="action" @click="toUpgrade">{{ $t('action.upgrade') }}</span>
      <span class="action" @click="toPrice">{{ $t('action.show_detail') }}</span>
    </div>
    <div class="remark-team__content">
      <el-form
        ref="formRef"
        :model="formData"
        :rules="rules"
        label-width="82px"
        :status-icon="false"
        @submit.prevent
      >
        <el-form-item :label="$tLabel($t('team_manager.dialog.team_name'))" required prop="team_name">
          <el-input v-model="formData.team_name" :placeholder="$t('team_manager.dialog.team_name')"></el-input>
        </el-form-item>
        <el-form-item :label="$tLabel($t('team_manager.dialog.member'))" prop="is_show_members">
          <el-checkbox v-model="formData.is_show_members">{{ $t('team_manager.dialog.visible_each_other') }}</el-checkbox>
        </el-form-item>
        <el-form-item :label="$tLabel($t('label.remark'))" prop="team_desc">
          <el-input :placeholder="$t('team_manager.dialog.input_remark')" :rows="3" type="textarea" maxlength="100" v-model="formData.team_desc" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addTeam(formRef)"
          >{{ $t('action.confirm') }}</el-button
        >
        <el-button @click="closeAddTeam">{{ $t('action.cancel') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, ref, defineProps, watch, nextTick } from 'vue'
import { useState } from '@/hooks/useVuex'
import { postTeamCooperation, putTeamCooperation } from '@/services/team.js'
import { message } from '@/hooks/useUI'
import { t } from 'app/i18n'

const props = defineProps({
  addVisible: {
    required: true,
    default: false
  },
  type: {
    required: false,
    default: 'add'
  },
  defaultData: {
    required: false,
    default: () => {
      return {}
    }
  }
})

watch(() => props.addVisible, (value) => {
  hasPremission.value = true
  if (props.type === 'update') {
    formData.value = {
      team_id: props.defaultData.team_id,
      team_name: props.defaultData.team_name,
      is_show_members: !!props.defaultData.is_show_members,
      team_desc: props.defaultData.team_desc
    }
  } else {
    formData.value = {
      team_name: '',
      is_show_members: true,
      team_desc: ''
    }
  }
  nextTick(() => {
    visible.value = value
  })
})

const { userInfo } = useState('user', ['userInfo'])
const emit = defineEmits(['addTeam', 'closeAddTeam'])
const hasPremission = ref(true)
const formRef = ref()
const visible = ref(false)
const formData = ref({
  team_name: '',
  is_show_members: true,
  team_desc: ''
})

const validateName = (rule, value, callback) => {
  if (value.trim()) {
    callback()
  } else {
    callback(new Error(t('team_manager.dialog.valid_team_name')))
  }
}

const rules = ref({
  team_name: [
    { required: true, message: t('team_manager.dialog.valid_team_name'), trigger: 'change' },
    { min: 1, max: 15, message: t('team_manager.dialog.valid_team_name_length'), trigger: 'blur' },
    { validator: validateName, trigger: 'blur' }
  ]
})

const addTeam = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      // visible.value = false
      if (formData.value.team_id) {
        putTeamCooperation({
          id: formData.value.team_id,
          team_name: formData.value.team_name.trim(),
          team_desc: formData.value.team_desc.trim(),
          is_show_members: formData.value.is_show_members === true ? 1 : 0
        }).then(res => {
          if ('code' in res) {
            message(res.message, 'error')
          } else {
            // updateVisible.value = false
            emit('addTeam')
            message(t('action.handle'))
          }
        })
      } else {
        postTeamCooperation({
          is_show_members: formData.value.is_show_members === true ? 1 : 0,
          team_name: formData.value.team_name.trim(),
          team_desc: formData.value.team_desc.trim()
        }).then(res => {
          if ('code' in res) {
            if (res.code === 1 && (userInfo.value.group === 'free' || userInfo.value.group === 'professional')) {
              hasPremission.value = false
            } else {
              message(res.message, 'error')
            }
          } else {
            visible.value = false
            // emit('refresh')
            emit('addTeam', true)
            message(t('team_manager.dialog.added'))
          }
        })
      }
    } else {
      return false
    }
  })
}

const toPrice = () => {
  window.open('/price', '_blank')
}

const toUpgrade = () => {
  // const query = {}
  // if (userInfo.value.group === 'free') {
  //   query.type = 'purchase'
  //   query.price_type = 'one_year'
  // } else if (userInfo.value.group === 'professional') {
  //   query.type = 'upgrade'
  //   query.group = 'enterprise'
  // }
  // router.push({
  //   name: 'subscribe',
  //   query: query
  // })
  let query = ''
  if (userInfo.value.group === 'free') {
    query = '/usercenter/subscribe?type=purchase&price_type=one_year'
    // query.type = 'purchase'
    // query.price_type = 'one_year'
  } else if (userInfo.value.group === 'professional') {
    // query.type = 'upgrade'
    // query.group = 'enterprise'
    query = '/usercenter/subscribe?type=upgrade&group=enterprise'
  }
  window.open(query, '_blank')
}

const closeAddTeam = () => {
  // visible.value = false
  emit('closeAddTeam')
}

</script>

<style lang="scss" scoped>
[data-theme='light'] .add-team__dialog {
  .no-count__wrap {
    background: #FFF2E8;
  }
}

@import '@/styles/common.scss';
.add-team__dialog {
  :deep(.el-dialog__body) {
    padding: 0 !important;
  }
  .remark-team__content {
    padding: 24px;
    box-sizing: border-box;
    height: 278px;
  }
  .no-count__wrap {
    box-sizing: border-box;
    height: 40px;
    padding: 9px 24px;
    font-size: 14px;
    line-height: 22px;
    // line-height: 40px;
    background: #431418;
    svg {
      float: left;
      margin-top: 2px;
      font-size: 18px;
      color: #f5222d;
    }
    span {
      float: left;
    }
    .info {
      margin-left: 6px;
    }
    .action {
      margin-left: 13px;
      color: #00ab7a;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.add-team__dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
