<template>
  <div class="owner-team__wrapper">
    <div class="owner-team__title">{{ $t('team_manager.owner_team') }}</div>
    <div
      class="content-wrap"
      v-loading="isLoading"
      :element-loading-text="$t('loading')"
      element-loading-background="var(--report-analyse-mask)"
    >
      <template v-if="teamNameList.length && !isLoading">
        <div class="sidebar-wrap">
          <owner-sidebar :list="teamNameList" :active="activeIndex" @changeIndex="changeSelectTeam" @refresh="refresh" />
        </div>
        <owner-list v-show="teamNameList.length" :info="teamInfo" @refresh="refresh" @updateData="updateData" />
      </template>
      <no-team v-if="!teamNameList.length && !isLoading" @refresh="refresh"/>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import emitter from '@/app/emitter.js'
import ownerSidebar from './components/owner-sidebar.vue'
import ownerList from './components/owner-list.vue'
import NoTeam from './components/no-team.vue'
import { getTeamMembers } from '@/services/team.js'

const route = useRoute()
const teamNameList = ref([])
const activeIndex = ref(0)
const isLoading = ref(true)
const teamInfo = computed(() => {
  if (teamNameList.value && teamNameList.value.length) {
    return teamNameList.value.find(item => item.team_id === activeIndex.value)
  }
  return []
})
// const teamInfo = ref({
//   info: {}
// })

onMounted(() => {
  // 传true表示进入页面
  refresh(true)
  initEvent()
})

const initEvent = () => {
  emitter.on('changeTeam', (id) => {
    activeIndex.value = id
  })
}

const changeSelectTeam = (team) => {
  activeIndex.value = team.team_id
}

const refresh = (flag) => {
  if (flag === 'next') {
    const index = teamNameList.value.map(item => item.team_id).indexOf(activeIndex.value)
    if ((index === teamNameList.value.length - 1 && teamNameList.value.length !== 1) || index === -1) {
      activeIndex.value = teamNameList.value[0].team_id
    } else {
      activeIndex.value = teamNameList.value[index + 1].team_id
    }
    teamNameList.value.splice(index, 1)
    return
  }
  getTeamMembers().then(res => {
    teamNameList.value = res
    isLoading.value = false
    if (res.length) {
      if (route.params.team_id && flag) {
        res.map(item => {
          if (item.team_id === parseInt(route.params.team_id)) {
            activeIndex.value = item.team_id
          }
        })
        if (!activeIndex.value) {
          activeIndex.value = res[0].team_id
        }
      } else {
        activeIndex.value = res[0].team_id
      }
    } else {
      activeIndex.value = 0
    }
  })
}

const updateData = () => {
  getTeamMembers().then(res => {
    teamNameList.value = res
  })
}

</script>
<style lang="scss" scoped>
[data-theme='light'] .owner-team__wrapper {
  background-color: white;
}
.owner-team__wrapper {
  padding: 0 24px;
  height: calc(100% - 85px);
  padding-bottom: 24px;
  .owner-team__title {
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
  }
  .content-wrap {
    border-radius: 2px;
    height: calc(100% - 47px);
    // overflow-y: auto;
    overflow: hidden;
    .sidebar-wrap {
      height: 100%;
      overflow-y: auto;
      padding-right: 10px;
      float: left;
    }
  }
  :deep(.nodata-wrap) {
    margin-top: 150px;
  }
}
</style>
