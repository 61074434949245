<template>
  <div class="no-team__wrap">
    <img src="@/assets/img/no-team-bg.png" alt="">
    <p class="no-team__text">{{ $t('team_manager.no_team_info') }}</p>
    <div class="btn-wrap">
      <el-button type="primary" @click="handleCreate">{{ $t('team_manager.create_team') }}</el-button>
    </div>
  </div>
  <add-team-dialog :addVisible="visible" @closeAddTeam="closeAddTeam" @addTeam="submitTeam"  />
  <upgrade-dialog
    v-model:show="isShowUpgrade"
    :showPoint="false"
    :showNormal="false"
    :showTeam="true"
    type="A"
    upgradeType="enterprise"
  ></upgrade-dialog>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import { useState } from '@/hooks/useVuex'
import addTeamDialog from './add-team-dialog.vue'
import upgradeDialog from '@/components/common/upgradeDialog.vue'
// import { postTeamCooperation } from '@/services/team.js'
// import { message } from '@/hooks/useUI'

const visible = ref(false)
const emit = defineEmits(['refresh'])
const { userInfo } = useState('user', ['userInfo'])
const isShowUpgrade = ref(false)

const handleCreate = () => {
  if (userInfo.value.group === 'free' || userInfo.value.group === 'professional') {
    isShowUpgrade.value = true
    return
  }
  visible.value = true
}

const closeAddTeam = () => {
  visible.value = false
}

const submitTeam = () => {
  emit('refresh')
}
</script>

<style lang="scss" scoped>
.no-team__wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .no-team__text {
    font-family: PingFang SC;
    font-size: 14px;
    color: var(--color-text-2);
  }
  .btn-wrap {
    padding-bottom: 40px;
  }
}
</style>
