<template>
  <div class="owner-list__wrapper">
    <div class="owner-list__header">
      <span class="title-text__wrap">
        <span class="team-logo">
          <svg-icon name="tuandui2"></svg-icon>
        </span>
        <span class="team-title__text">{{ props.info.team_name }}</span>
        <span class="team-title__id">(ID: {{props.info.team_id}})</span>
        <span class="open-task__action" @click="openTask">{{ $t('team_manager.show_team_task') }}</span>
        <!-- <span class="add-user" @click="addUser">
          <svg-icon name="shezhi"></svg-icon>
        </span> -->
        <template v-if="isManager">
          <add-user-dialog v-if="!isExpire" :teamId="props.info.team_id" :invitedMemvers="isInvitedMemberIds" :joinedMembers="isJoinMemberIds" @updateMembers="updateMembers">
            <span class="add-user">
              <el-tooltip
                :content="$t('team_manager.add_member')"
                :effect="$isDark() ? 'dark' : 'light'"
                popper-class="plan-info-tips"
                :offset="6"
                placement="top"
              >
                <span><svg-icon name="tianjiarenyaun"></svg-icon></span>
              </el-tooltip>
            </span>
          </add-user-dialog>
          <span class="add-user" v-else>
            <el-tooltip
              :content="$t('team_manager.add_member')"
              :effect="$isDark() ? 'dark' : 'light'"
              popper-class="plan-info-tips"
              :offset="6"
              placement="top"
            >
              <span><svg-icon @click="isShowUpgrade=true" name="tianjiarenyaun"></svg-icon></span>
            </el-tooltip>
          </span>
          <el-tooltip
            :content="$t('team_manager.edit_team')"
            :effect="$isDark() ? 'dark' : 'light'"
            popper-class="plan-info-tips"
            :offset="5"
            placement="top"
          >
            <span class="update-team" @click="updateHandle">
              <svg-icon name="bianji"></svg-icon>
            </span>
          </el-tooltip>
          <el-tooltip
            :content="$t('team_manager.dele_team')"
            :effect="$isDark() ? 'dark' : 'light'"
            popper-class="plan-info-tips"
            :offset="5"
            placement="top"
          >
            <span class="dele-team" @click="handleDele">
              <svg-icon name="shanchu"></svg-icon>
            </span>
          </el-tooltip>
        </template>
        <el-tooltip
          v-else
          :content="$t('team_manager.quit_team')"
          :effect="$isDark() ? 'dark' : 'light'"
          popper-class="plan-info-tips"
          :offset="5"
          placement="top"
        >
          <span class="out-team" @click="handleOut">
            <svg-icon name="tuichutuandui1"></svg-icon>
          </span>
        </el-tooltip>
      </span>
    </div>
    <div class="team-desc__wrap">
      <el-tooltip
        :content="props.info.team_desc"
        :effect="$isDark() ? 'dark' : 'light'"
        popper-class="plan-info-tips"
        :offset="0"
        placement="bottom"
      >
        <span class="team-desc__text">
          {{ props.info.team_desc }}
        </span>
      </el-tooltip>
      <span class="team-search__wrap">
        <el-input
          v-model="searchValue"
          :placeholder="$t('team_manager.place_input_content')"
          class="input-with-select"
          @keyup.enter="filterList"
        >
          <template #prepend>
            <el-select v-model="searchType" style="width: 100px" popper-class="detect-operate">
              <el-option :label="$t('label.email')" value="email" />
              <el-option :label="$t('label.username')" value="username" />
            </el-select>
          </template>
          <template #suffix>
            <el-icon class="el-input__icon" @click="filterList">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </span>
      <span v-if="props.info.is_show_members !== 1 && !isManager" class="team-isopen__info">
        <svg-icon name="info"></svg-icon>
        {{ $t('team_manager.invisible_each_other') }}
      </span>
    </div>
    <div class="team-list__wrap">
      <div class="list-wrap">
        <el-table :data="tableData" style="width: 100%" :tooltip-effect="$isDark() ? 'dark' : 'light'">
          <el-table-column prop="username" :label="$t('label.username')" width="180" :show-overflow-tooltip="true">
            <template #default="scope">
              {{ scope.row.username === userInfo.username ? scope.row.username + $t('team_manager.is_mine') : scope.row.username }}
            </template>
          </el-table-column>
          <el-table-column prop="email" :label="$t('label.email')" width="200" :show-overflow-tooltip="true" />
          <el-table-column prop="role" :label="$t('label.role')" width="100">
            <template #default="scope">
              {{ getRole(scope.row.user_role).text }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$t('label.remark')">
            <template #default="scope">
              <span class="note">{{scope.row.note || '-'}}</span>
              <span v-if="scope.row.username !== userInfo.username || (scope.row.username === userInfo.username && scope.row.user_role === 0)" class="edit-wrap" @click="handleUpdateRemark(scope.row)">
                <svg-icon name="bianji1"></svg-icon>
              </span>
              <!-- <el-icon style="cursor: pointer;"><EditPen /></el-icon> -->
            </template>
          </el-table-column>
          <el-table-column prop="invited_state" :label="$t('label.status')" width="100"  v-if="isManager">
            <template #default="scope">
              <span>{{getInvitedStatus(scope.row.invited_state).text}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="option" :label="$t('label.action')" width="180" v-if="isManager">
            <template #default="scope">
              <div class="operation-wrap" v-if="scope.row.username !== userInfo.username">
                <template v-if="scope.row.invited_state === 2">
                  <div class="remove" @click="deleteMember(scope.row)">{{ $t('team_manager.dele_member') }}</div>
                </template>
                <template v-else-if="scope.row.invited_state === 1">
                  <div class="remove" @click="deleteMember(scope.row)">{{ $t('action.remove') }}</div>
                </template>
                <template v-else-if="scope.row.invited_state === 3">
                  <div class="reinvite" @click="reinviteMember(scope.row)">{{ $t('team_manager.re_invite') }}</div>
                  <div class="remove" @click="deleteMember(scope.row)">{{ $t('action.remove') }}</div>
                </template>
                <template v-else-if="scope.row.invited_state === 4">
                  <div class="reinvite" @click="reinviteMember(scope.row)">{{ $t('team_manager.re_invite') }}</div>
                  <div class="remove" @click="deleteMember(scope.row)">{{ $t('action.remove') }}</div>
                </template>
                <template v-else>
                  -
                </template>
              </div>
              <div v-else>
                -
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="pagination-wrap">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
        />
      </div> -->
    </div>
    <el-dialog
      v-model="deleDialogVisible"
      :title="$t('label.attention')"
      width="470px"
    >
      <div class="delete-team__content">
        <svg-icon name="info"></svg-icon>
        <span style="line-height: 21px;">{{ $t('team_manager.dele_team_info') }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="deleDialogVisible = false">{{ $t('action.cancel') }}</el-button>
          <el-button @click="deleTeam"
            >{{ $t('action.confirm_remove') }}</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="outDialogVisible"
      :title="$t('label.attention')"
      width="470px"
    >
      <div class="delete-team__content">
        <svg-icon name="info"></svg-icon>
        <span style="line-height: 21px;">{{ $t('team_manager.quit_team_info', [props.info.team_name]) }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="outDialogVisible = false">{{ $t('action.cancel') }}</el-button>
          <el-button @click="outTeam"
            >{{ $t('action.confirm_quit') }}</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="remarkDialogVisible"
      :title="$t('team_manager.place_input_remark')"
      width="470px"
    >
      <div class="remark-team__content">
        <el-input :placeholder="$t('team_manager.place_input_remark')" :rows="8" type="textarea" maxlength="100" v-model="remarkValue" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateRemark"
            >{{ $t('action.confirm') }}</el-button
          >
          <el-button @click="remarkDialogVisible = false">{{ $t('action.cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <add-team-dialog :defaultData="props.info" :addVisible="updateVisible" @closeAddTeam="closeUpdateTeam" @addTeam="updateTeam" type="update" />
  </div>
  <upgrade-dialog
    v-model:show="isShowUpgrade"
    :showPoint="false"
    :showNormal="false"
    :showTeam="true"
    type="A"
    upgradeType="enterprise"
  ></upgrade-dialog>
</template>

<script setup>
// import { useStore } from 'vuex'
import addUserDialog from './add-user-dialog.vue'
import dayjs from 'dayjs'
import { computed, defineProps, defineEmits, ref, watch, onMounted } from 'vue'
import { deleteTeamCooperation, putTeamMembers, deleteTeamMembers, postTeamMembers } from '@/services/team.js'
import { Search } from '@element-plus/icons-vue'
import addTeamDialog from './add-team-dialog.vue'
import { useState } from '@/hooks/useVuex'
import { useRouter } from 'vue-router'
import { message } from '@/hooks/useUI'
import upgradeDialog from '@/components/common/upgradeDialog.vue'
import { t } from 'app/i18n'

// import Pagination from '@/components/common/Pagination'
const router = useRouter()

const tableData = ref([])

// const store = useStore()
const { userInfo } = useState('user', ['userInfo'])

const isManager = computed(() => {
  return props.info.team_owner === userInfo.value.username
})

const isShowUpgrade = ref(false)

const isExpire = computed(() => {
  if (Object.keys(props.info).length > 0) {
    const deleteTime = props.info.expire_time && props.info.expire_time.$date
    if (!deleteTime) return false
    // const expireTime = +dayjs(deleteTime).subtract(1, 'months')
    const expireTime = +dayjs(deleteTime)
    return expireTime < +new Date()
  } else {
    return false
  }
})

const isInvitedMemberIds = computed(() => {
  return props.info && props.info.members && props.info.members.filter(item => item.invited_state === 1).map(item => item.user_id)
})

const isJoinMemberIds = computed(() => {
  return props.info && props.info.members && props.info.members.filter(item => item.invited_state === 2).map(item => item.user_id)
})

const props = defineProps({
  info: {
    required: true,
    default: () => {
      return {}
    }
  }
})

watch(() => props.info, (value) => {
  searchValue.value = ''
  tableData.value = value.members || []
})

onMounted(() => {
  tableData.value = props.info.members
})

const deleDialogVisible = ref(false)
const remarkDialogVisible = ref(false)
const updateVisible = ref(false)
const outDialogVisible = ref(false)
const searchValue = ref('')
const selectMember = ref(null)
const remarkValue = ref('')
const searchType = ref('username')
const emit = defineEmits(['refresh', 'updateData'])

const filterList = () => {
  tableData.value = props.info.members.filter(item => {
    if (searchType.value === 'username') {
      return item.username.indexOf(searchValue.value) > -1
    } else {
      return item.email.indexOf(searchValue.value) > -1
    }
  })
}

const updateMembers = () => {
  emit('updateData')
}

const updateHandle = () => {
  updateVisible.value = true
}

const handleOut = () => {
  outDialogVisible.value = true
}
const handleDele = () => {
  deleDialogVisible.value = true
}

const deleTeam = () => {
  deleteTeamCooperation({
    team_id: props.info.team_id,
    user_id: Math.floor(Math.random() * 100)
  }).then(res => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      message(t('action.delete'))
      deleDialogVisible.value = false
      emit('refresh', 'next')
    }
  })
}

const outTeam = () => {
  deleteTeamMembers({
    team_id: props.info.team_id,
    user_id: userInfo.value.user_id
  }).then(res => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      message(t('action.quit_success'))
      outDialogVisible.value = false
      emit('refresh', 'next')
    }
  })
}

const openTask = () => {
  router.push({
    name: 'teamtask',
    query: {
      teamId: props.info.team_id
    }
  })
}

const handleUpdateRemark = (item) => {
  remarkDialogVisible.value = true
  selectMember.value = item
  remarkValue.value = item.note
}

const updateRemark = (item) => {
  putTeamMembers({
    user_id: selectMember.value.user_id,
    team_id: props.info.team_id,
    note: remarkValue.value
  }).then(res => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      message(t('action.edit_success'))
      remarkDialogVisible.value = false
      emit('updateData')
    }
  })
}

const closeUpdateTeam = () => {
  updateVisible.value = false
}

const updateTeam = () => {
  // emit('add', formData)
  updateVisible.value = false
  emit('updateData')
}

const reinviteMember = (member) => {
  postTeamMembers({
    user_id: member.user_id,
    team_id: member.team_id
  }).then(res => {
    if ('code' in res) {
      // if (res.code === 1) {
      //   store.commit('SHOWFREQUENCY', '单个团队添加成员超过 10 个限制')
      // } else {
      message(res.message, 'error')
      // }
    } else {
      message(t('action.invite_success'))
      emit('updateData')
    }
  })
}

const deleteMember = (member) => {
  deleteTeamMembers({
    user_id: member.user_id,
    team_id: props.info.team_id
  }).then(res => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      message(t('action.delete'))
      emit('updateData')
    }
  })
}

const getInvitedStatus = (status) => {
  switch (status) {
    case 1:
      return { text: t('team_manager.dialog.invited'), icon: require('@/assets/img/team/waiting.svg') }
    case 2:
      return { text: t('team_manager.dialog.joined'), icon: require('@/assets/img/team/joined.svg') }
    case 3:
      return { text: t('team_manager.rejected'), icon: require('@/assets/img/team/deny.svg') }
    case 4:
      return { text: t('team_manager.quited'), icon: require('@/assets/img/team/exit.svg') }
    default:
      return ''
  }
}

const getRole = (role) => {
  switch (role) {
    case 0:
      return { text: t('team_manager.admin'), icon: require('@/assets/img/team/manager-list.svg') }
    case 1:
      return { text: t('team_manager.member'), icon: require('@/assets/img/team/member-list.svg') }
    default:
      return ''
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.owner-list__wrapper {
  float: left;
  box-sizing: border-box;
  padding-left: 5px;
  width: calc(100% - 161px);
  :deep(.el-input__inner) {
    color: var(--color-text-2);
  }
  .title-text__wrap {
    line-height: 32px;
    display: block;
    font-size: 16px;
    .team-logo {
      float: left;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      margin-top: 5px;
      border-radius: 3px;
      background: #00ab7a;
      color: #fff;
      margin-right: 5px;
    }
    .team-title__text {
      font-weight: 600;
    }
    .team-title__id {
      font-size: 14px;
      color: var(--color-text-2);
      margin-left: 6px;
    }
    .open-task__action {
      margin: 0 15px;
      color: #00ab7a;
      cursor: pointer;
    }
    // .add-user {
    //   margin-left: 8px;
    //   cursor: pointer;
    //   color: var(--color-text-3);
    //   font-size: 20px;
    // }
    .add-user, .dele-team, .update-team, .out-team {
      margin-left: 8px;
      cursor: pointer;
      color: var(--color-text-2);
      font-size: 20px;
      position: relative;
      top: 2px;
      &:hover {
        color: var(--color-priamry);
      }
    }
  }
  .team-desc__wrap {
    line-height: 32px;
    overflow-x: hidden;
    .team-desc__text {
      float: left;
      font-size: 12px;
      color: var(--color-text-2);
      max-width: calc(100% - 200px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .team-search__wrap {
      float: right;
      :deep(.el-input-group__prepend) {
        width: 100px;
        padding: 0 !important;
        border: none !important;
        background-color: var(--color-bg-1);
      }
      .el-input__icon {
        cursor: pointer;
      }
    }
    .team-isopen__info {
      float: right;
      color: var(--color-text-3);
      margin-right: 24px;
      line-height: 32px;
      &>svg {
        // margin-right: 0px;
        font-size: 18px;
        // margin-top: 2px;
        color:#fa8c16;
      }
    }
  }
  .team-list__wrap {
    margin-top: 15px;
    height: calc(100vh - 273px);
    background: var(--detect-config-bg);
    border-radius: 2px;
    overflow-y: auto;
    .list-wrap {
      padding: 24px;
      .note {
        margin-right: 7px;
      }
      .operation-wrap {
        overflow: hidden;
        &>div {
          float: left;
        }
        .reinvite+.remove {
          margin-left: 10px;
        }
      }
      .remove, .reinvite {
        color: #00cc92;
        cursor: pointer;
      }
      .edit-wrap {
        cursor: pointer;
        margin-left: 5px;
        color: #00ab7a;
      }
    }
    .pagination-wrap {
      padding: 10px 3px 24px;
      text-align: right;
    }
    :deep(.el-table__row) {
      height: 48px;
    }
    :deep(.el-table__header) {
      height: 48px;
      .el-table__cell {
        background-color: var(--color-bg-3);
      }
    }
  }
}
</style>

<style lang="scss">
.delete-team__content {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
</style>
